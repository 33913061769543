<template>
    <footer class="footer_widgets">
        <div class="footer_top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-5">
                        <div class="widgets_container contact_us">
                            <div class="footer_logo" align="center">
                                <router-link :to="{name: 'catalog'}"><img width="150" :src="logo" alt=""></router-link>
                            </div>
                            <div class="footer_contact">
                                <p>Продажа оборудования для автополива по оптовым ценам.</p>
                                <div class="customer_support">
                                    <!-- <div class="support_img_icon">
                                        <img :src="iconPhone" alt="">
                                    </div> -->
                                    <div class="support_list">
                                        <p>
                                            <span>Поддержка:</span>
                                        </p>
                                        <p>
                                            <i class="fa fa-phone"></i>
                                            <a href="tel:+79205581808">+7 (920) 558-18-08</a>
                                        </p>    
                                        <p>
                                            <i class="fa fa-whatsapp"></i>
                                            <a target="_blank" href="https://wa.me/79205581808">+7 (920) 558-18-08</a>
                                        </p>  
                                        <p>  
                                            <i class="fa fa-telegram"></i>
                                            <a target="_blank" href="https://t.me/+79205581808">+7 (920) 558-18-08</a>
                                        </p>
                                        <p>    
                                            <i class="fa fa-envelope"></i>
                                            <a href="mailto:89205581808@mail.ru">89205581808@mail.ru</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-7">
                        <div class="widgets_container widgets_subscribe">
                            <div class="footer_social">
                                <div class="as-videobox" style="justify-content: center !important;">
                                    <div>
                                        <iframe width="350" height="230"
                                            src="https://www.youtube.com/embed/Fu6uhOw4KY4?si=mZ61n31H-8V4bSwf&amp">
                                        </iframe>
                                    </div>
                                    <!-- <div>
                                        <iframe width="210" height="155"
                                            src="https://www.youtube.com/embed/Fu6uhOw4KY4?si=mZ61n31H-8V4bSwf&amp">
                                        </iframe>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="widgets_container widget_menu">
                            <h3>Информация</h3>
                            <div class="footer_menu">
                                <ul>
                                    <li><a href="#">Оплата</a></li>
                                    <li><a href="#">Возврат</a></li>
                                    <li><a href="#">Гарантия</a></li>
                                    <li><a href="#">Доставка</a></li>
                                    <li><a href="#">Вопрос-ответ</a></li>
                                </ul>
                                <ul>
                                    <li><router-link :to="{ name: 'catalog', query: {page: 1} }">Каталог</router-link></li>
                                    <li><a target="_blank" href="https://magazin-poliva1.ru/smeta/">Калькулятор</a></li>
                                    <li><router-link :to="{ name: 'projectOrder' }">Заказать проект</router-link></li>
                                    <li><router-link :to="{ name: 'orderInstallation' }">Заказать монтаж</router-link></li>
                                    <li><router-link :to="{ name: 'onlineTraining' }">Онлайн обучение</router-link></li>
                                    <li><router-link :to="{ name: 'forDevelopers' }">Застройщикам ИЖС</router-link></li>
                                    <li><router-link :to="{ name: 'contact' }">Контакты</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_bottom">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="copyright_area" align="center">
                            <p class="copyright-text">&copy; 2025  Made by <a href="https://annasoft.ru/" target="_blank">Annasoft</a> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import iconPhone from "@/assets/images/icon/hotline.png"
    import logo from "@/assets/images/logo/logo-blue.png"
    export default {
        name: "FooterComponent",
        data () {
            return {
                iconPhone,
                logo
            }
        }
    }
</script>