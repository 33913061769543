<template>
    <div class="offcanvas_menu">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="canvas_open" @click="showMobileMenu">
                        <a><i class="icon-menu"></i></a>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <n-drawer v-model:show="menuDrawerVisble" :width="menuDrawerWidth" placement="left">
        <n-drawer-content title=" " closable>
        <div class="offcanvas_menu_wrapper active">
            <div class="welcome_text">
                <p>Добро пожаловать!</p>
            </div>
            <div class="top_right">
                <ul>
                    <li>
                        <router-link :to="{ name: 'userWishlist' }" @click="showMobileMenu">
                            <i class="icon-heart"></i> Избранное ({{ wishlistQty }})
                        </router-link>
                    </li>
                    <li v-if="!user"><router-link :to="{ name: 'login' }" @click="showMobileMenu">Вход</router-link></li>
                    <li v-if="user">
                        <router-link :to="{ name: 'userAccount' }" @click="showMobileMenu">Личный кабинет</router-link>
                    </li>
                </ul>
            </div>
            <div class="search-container">
                <div class="search_box">
                    <input placeholder="Поиск..." type="text">
                    <button type="submit" @click="showMobileMenu"><i class="icon-search"></i></button>
                </div>
            </div>
            <div class="customer_support">
                <!-- <div class="support_img_icon">
                    <img :src="iconPhone" alt="">
                </div> -->
                <div class="mobile_customer_support">
                    <p>
                        <i class="fa fa-phone"></i>
                        <a href="tel:+79205581808">+7 (920) 558-18-08</a>
                    </p> 
                    <div class="support_social">
                        <div>  
                            <a href="mailto:89205581808@mail.ru"><i class="fa fa-envelope"></i></a>
                        </div>
                        <div>  
                            <a target="_blank" href="https://t.me/+79205581808"><i class="fa fa-telegram"></i></a>
                        </div>
                        <div>
                            <a target="_blank" href="https://wa.me/79205581808"><i class="fa fa-whatsapp"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mini_cart_canvas">
                <div class="mini_cart_wrapper">
                    <router-link :to="{ name: 'userCart' }" @click="showMobileMenu">
                        <span class="icon-shopping-cart"></span>
                    </router-link>
                    <span class="cart_quantity">{{ cartQty }}</span>
                </div>
            </div>
            <div id="menu" class="text-left ">
                <ul class="offcanvas_main_menu">
                    <li class="menu-item-has-children">
                        <router-link :to="{ name: 'catalog', query: {page: 1} }" @click="showMobileMenu">Каталог</router-link>
                    </li>
                    <li class="menu-item-has-children">
                        <a target="_blank" href="https://magazin-poliva1.ru/smeta/">Калькулятор</a>
                    </li>
                    <li class="menu-item-has-children">
                        <router-link :to="{ name: 'projectOrder' }" @click="showMobileMenu">Заказать проект</router-link>
                    </li>
                    <li class="menu-item-has-children">
                        <router-link :to="{ name: 'orderInstallation' }" @click="showMobileMenu">Заказать монтаж</router-link>
                    </li>
                    <li class="menu-item-has-children">
                        <router-link :to="{ name: 'onlineTraining' }" @click="showMobileMenu">Онлайн обучение</router-link>
                    </li>
                    <li class="menu-item-has-children">
                        <router-link :to="{ name: 'forDevelopers' }" @click="showMobileMenu">Застройщикам ИЖС</router-link>
                    </li>
                    <li class="menu-item-has-children">
                        <router-link :to="{ name: 'contact' }" @click="showMobileMenu">Контакты</router-link>
                    </li>
                </ul>
            </div>
        </div>
        </n-drawer-content>
    </n-drawer>
</template>

<script>
import iconPhone from "@/assets/images/icon/icon_phone.png"

export default {
    name: "MobileMenuComponent",
    data () {
        return {
            iconPhone,
            menuDrawerVisble: false,
			menuDrawerWidth: 400,
        }
    },
    mounted() {
        if ((window.innerWidth - 20) < 400) {
            this.menuDrawerWidth = window.innerWidth - 20
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        cartQty () {
            if (this.userToken) {
                return this.$store.getters.cartQty
            } else {
                return this.$store.getters['localCart/localCartQty']
            }
        },
        wishlistQty () {
            if (this.userToken) {
                return this.$store.getters.wishlistQty
            } else {
                return this.$store.getters['localWishlist/localWishlistQty']
            }
        },
        user () {
            return this.$store.getters.user
        }
    },
    methods: {
        showMobileMenu(el) {
            if (el) {
                el.preventDefault()
            }
            this.menuDrawerVisble = !this.menuDrawerVisble
        },
    }
}
</script>