import Axios from 'axios'
import {backendPath} from "@/main.js"

export default {
    state: {
        checkoutList: [],
        checkoutListLoading: false,
    },
    getters: {
        checkoutList: state => {
            return state.checkoutList
        },
        checkoutListLoading: state => {
            return state.checkoutListLoading
        },
        checkoutAmount: state => {
            if (state.checkoutList.length > 0) {
                return state.checkoutList.reduce((acc,item) => acc += (item.quantity*item.good.clientPrice), 0)
            } else {
                return 0
            }
        },
    },
    mutations: {
        setCheckoutList (state, payload) {
            state.checkoutList = payload
        },
        checkoutListLoadingSwitch (state, payload) {
            state.checkoutListLoading = payload
        },
        setCheckoutListItemPrice (state, payload) {
            let checkoutListitem = state.checkoutList.find(item => item.good.id === payload.id)
            if (checkoutListitem) {
                checkoutListitem.good.clientPrice = payload.clientPrice
            }
        }
    },
    actions: {
        clearCheckoutList({commit}) {
            commit('setCheckoutList', [])
        },
        setCheckoutListItemPrice({commit}, params) {
            commit('setCheckoutListItemPrice', params)
        },
        async loadCheckoutList({commit}, userToken) {
            commit('checkoutListLoadingSwitch', true)
            let url = `${backendPath}/api/v1/cart/`
            await Axios({
                method: 'get',
                headers: {
                    "Authorization": `Token ${userToken}`,
                },
                url: url,
            }).then(response => {
                let cartList = response.data.data
                cartList.forEach(element => {
                    element.good.clientPrice = element.good.price1
                })
                commit('setCheckoutList', cartList)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                commit('checkoutListLoadingSwitch', false)
            })
        }
    }
}