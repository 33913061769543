<template>
    <BreadcrumbComponent 
        name="Каталог"
        :categoryName="category.name"
    >
    </BreadcrumbComponent>
    <div class="shop_area shop_reverse">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-12 category-sidebar-widget">
                    <SidebarComponent />
                </div>
                <div class="col-lg-9 col-md-12">
                    <CatalogBanner />
                    <div class="category-menu">
                        <n-flex @click="showCategoryMenu">
                            <n-button quaternary type="info" >
                                <i class="fa fa-bars"></i>
                            </n-button>
                            <div class="category-menu-title">
                                Категории
                            </div>
                        </n-flex>
                    </div>
                    <div class="shop_title">
                        <h1 v-if="category">{{ category.name }}</h1>
                        <h1 v-else>Каталог</h1>
                    </div>
                    <!-- <CatalogToolbar /> -->
                    <div v-if="goods_list_loading" class="row shop_wrapper grid_3">
                        <CatalogPreviewComponent />
                    </div>
                    <div v-else class="row shop_wrapper grid_3">
                        <div v-for="goodItem in goods_list" v-bind:key="goodItem.id" class="col-md-4 col-sm-6 col-lg-4">
                            <CatalogGoodComponent 
                                :goodInfo="goodItem"
                                :manufacturer="goodItem.manufacturer"
                                :images="goodItem.images"
                                >
                            </CatalogGoodComponent>
                        </div>
                    </div>
                    <PaginationComponent /> 
                </div>
            </div>
        </div>
    </div>
    <n-drawer v-model:show="categoryDrawerVisble" :width="categoryDrawerWidth" placement="left">
        <n-drawer-content title="Категории" closable>
            <SidebarComponent v-on:myevent="showCategoryMenu" />
        </n-drawer-content>
    </n-drawer>
</template>

<script>
import PaginationComponent from '@/components/Catalog/PaginationComponent.vue'
import SidebarComponent from '@/components/Catalog/SidebarComponent.vue'
import CatalogBanner from '@/components/Catalog/CatalogBanner.vue'
import BreadcrumbComponent from '@/components/Basic/BreadcrumbComponent.vue'
import CatalogGoodComponent from '@/components/Catalog/CatalogGoodComponent.vue'
import CatalogPreviewComponent from '@/components/Catalog/CatalogPreviewComponent.vue'
// import CatalogToolbar from '@/components/Catalog/CatalogToolbar.vue'

export default {
    name: "CatalogContentComponent",
    data () {
        return {
            categoryDrawerVisble: false,
			categoryDrawerWidth: 400,
        }
    },
    components: {
        SidebarComponent,
        CatalogBanner,
        PaginationComponent,
        BreadcrumbComponent,
        CatalogGoodComponent,
        CatalogPreviewComponent
    },
    computed: {
        goodsQty() {
            return this.$store.getters.goods_qty
        },
        goods_list() {
            return this.$store.getters.goods_list
        },
        goods_list_loading() {
            return this.$store.getters.goods_list_loading
        },
        category () {
            return this.$store.getters.category
        }
    },
    mounted() {
        if ((window.innerWidth - 20) < 400) {
            this.categoryDrawerWidth = window.innerWidth - 20
        }
    },
    methods: {
        showCategoryMenu(el) {
            if (el) {
                el.preventDefault()
            }
            this.categoryDrawerVisble = !this.categoryDrawerVisble
        },
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                if (this.$route.query.category_id) {
                    this.$store.dispatch('loadCategoryInfo', this.$route.query.category_id)
                } else {
                    this.$store.dispatch('loadCategoryInfo', "")
                }
            }
        }
    }
}
</script>

<style scoped>
.n-button {
    padding: 0;
}
.n-button i {
    color: #333;
}
.category-menu {
    padding: 7px;
    border: 1px solid #727272;
    margin-bottom: 10px;
}
.category-menu-title {
    align-self: center; 
    font-size: 20px; 
    font-weight: bold;
}
.fa-bars {
    font-size: 30px;
}
@media only screen and (min-width: 992px) {
  .category-menu {
    display: none !important;
  }
}
</style>