<template>
    <aside class="sidebar_widget">
        <div class="widget_inner">
            <div 
                class="widget_list widget_filter"
                v-show="categories_list.length == 0"
            >
                <ul>
                    <li v-for="item in categoriesDemoCount" :key="item.id">
                        <n-skeleton height="40px" round />
                    </li>
                </ul>
            </div>
            <div class="widget_list widget_filter">
                <n-collapse 
                    arrow-placement="right"
                >
                    <!-- первый уровень -->
                    <div 
                        class="n-collapse-item"
                        v-for="catLev1 in categories_list"
                        :key="catLev1.id"
                    >
                        <n-collapse-item 
                            v-if="catLev1.items.length > 0"
                            :title="catLev1.category.name" 
                            :name="catLev1.id"
                        >   
                            <!-- второй уровень -->
                            <div 
                                class="n-collapse-item"
                                v-for="catLev2 in catLev1.items"
                                :key="catLev2.id"
                            >
                                <n-collapse 
                                    arrow-placement="right"
                                >
                                    <n-collapse-item 
                                        v-if="catLev2.items.length > 0"
                                        :title="catLev2.category.name" 
                                        :name="catLev2.id"
                                    >
                                    <!-- третий уровень -->
                                    <div 
                                        class="n-collapse-item"
                                        v-for="catLev3 in catLev2.items"
                                        :key="catLev3.id"
                                    >   
                                        <div class="category-link">
                                            <router-link 
                                                @click="chooseCategory"
                                                :to="{ name: 'catalog', query: {category_id: catLev3.category.id, page: 1}}"
                                            >{{ catLev3.category.name }}
                                            </router-link>
                                        </div>
                                    </div>
                                    </n-collapse-item>
                                    <div class="category-link" v-else>
                                        <router-link 
                                            @click="chooseCategory"
                                            :to="{ name: 'catalog', query: {category_id: catLev2.category.id, page: 1}}"
                                        >{{ catLev2.category.name }}
                                        </router-link>
                                    </div>
                                </n-collapse>
                            </div>
                        </n-collapse-item>
                        <div class="category-link" v-else>
                            <router-link 
                                @click="chooseCategory"
                                :to="{ name: 'catalog', query: {category_id: catLev1.category.id, page: 1}}"
                            >{{ catLev1.category.name }}
                            </router-link>
                        </div>
                    </div>
                </n-collapse>
            </div>
        </div>
    </aside>
</template>

<script>
export default {
    name: 'SidebarComponent',
    // props: ['categoryDrawerVisble'],
    data () {
        return {
            catDrawerVisble: this.categoryDrawerVisble,
            categoriesDemoCount: 15,
        }
    },
    computed: {
        categories_list () {
            return this.$store.getters.categories_list
        },
        categoriesListLoading () {
            return this.$store.getters.categories_list_loading
        }
    },
    methods: {
        loadCategoriesList () {
            this.$store.dispatch('loadCategoriesList')
        },
        chooseCategory() {
            this.$emit('myevent')
        },
}   ,
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.loadCategoriesList()
            }
        }
    }
}
</script>

<style scoped>
.as-category-link {
    padding: 10px;
}
.vcpg[data-v-63943e43] .vcp__body {
    height: 100% !important;
}

.n-collapse .n-collapse-item .n-collapse-item {
    margin-left: 5px;
    padding-top: 10px;
}
.n-collapse .n-collapse-item .n-collapse-item__content-wrapper .n-collapse-item__content-inner {
    padding-top: 0px !important;
}
.category-link {
    margin-left: 5px !important;
    padding-top: 10px !important;
}
.n-collapse .n-collapse-item .n-collapse-item__header {
    font-weight: 700;
}
@media only screen and (max-width: 992px) {
    .widget_inner {
        border: none;
    }
}
</style>
