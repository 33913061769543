import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import goods_list from './modules/Catalog/goods_list'
import good from './modules/Catalog/good'
import categories_list from './modules/Catalog/categories_list'
import category from './modules/Catalog/category'
import user from './modules/User/user'
import cart from './modules/User/cart'
import localCart from './modules/User/localCart'
import wishlist from './modules/User/wishlist'
import localWishlist from './modules/User/localWishlist'
import checkout from './modules/User/checkout'
import order_list from './modules/User/Order/order_list'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    categories_list,
    category,
    goods_list,
    good,
    user,
    cart,
    localCart,
    wishlist,
    localWishlist,
    checkout,
    order_list
  },
  plugins: [createPersistedState()]
})
