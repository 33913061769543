<template>
    <div class="header_middle">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-6">
                    <div class="logo">
                        <router-link :to="{name: 'catalog'}"><img width="150" :src="logo" alt="Магазин полива №1"></router-link>
                    </div>
                </div>
                <div class="col-lg-9 col-md-6">
                    <div class="middel_right">
                        <div class="search-container">
                            <div>
                                <div class="search_box">
                                    <input placeholder="Поиск..." type="text">
                                    <button type="submit"><i class="icon-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="customer_support">
                            <!-- <div class="support_img_icon">
                                <img :src="iconPhone" alt="">
                            </div> -->
                            <div class="header_support_list">
                                <p>
                                    <i class="fa fa-phone"></i>
                                    <a href="tel:+79205581808">+7 (920) 558-18-08</a>
                                </p>  
                                <div class="support_social">
                                    <div>  
                                        <a href="mailto:89205581808@mail.ru"><i class="fa fa-envelope"></i></a>
                                    </div>
                                    <div>  
                                        <a target="_blank" href="https://t.me/+79205581808"><i class="fa fa-telegram"></i></a>
                                    </div>
                                    <div>
                                        <a target="_blank" href="https://wa.me/79205581808"><i class="fa fa-whatsapp"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import logo from "@/assets/images/logo/logo-white.png"
import iconPhone from "@/assets/images/icon/icon_phone.png"
export default {
    name: "HeaderMiddleComponent",
    data () {
        return {
            iconPhone,
            logo
        }
    }
}
</script>