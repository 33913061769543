export default {
    namespaced: true,
    state: {
        localCart: [],
    },
    getters: {
        localCart: state => {
            return state.localCart
        },
        localCartQty: state => {
            return state.localCart.length
        },
        localCartAmount: state => {
            if (state.localCart.length > 0) {
                return state.localCart.reduce((acc,item) => acc += (item.quantity*item.good.price1), 0)
            } else {
                return 0
            }
        },
    },
    mutations: {
        addCartItem (state, payload) {
            let cartItem = state.localCart.find(cartItem => cartItem.good.id === payload.good.id)
            if (cartItem) {
                cartItem.quantity += payload.quantity
            } else {
                state.localCart.push(payload)
            }
        },
        delCartItem (state, payload) {
            let cartItem = state.localCart.find(cartItem => cartItem.good.id === payload.good.id)
            if (cartItem) {
                if (cartItem.quantity <= payload.quantity) {
                    state.localCart = state.localCart.filter(cartItem => cartItem.good.id !== payload.good.id)
                }
                if (cartItem.quantity > payload.quantity) {
                    cartItem.quantity -= payload.quantity
                }
            }
        },
        setCartItem (state, payload) {
            let cartItem = state.localCart.find(cartItem => cartItem.good.id === payload.good.id)
            if (cartItem) {
                cartItem.quantity = payload.quantity
            }
        }
    },
    actions: {
        async addSetDelCartItem({commit}, params) {
            let action = params.action
            this.dispatch('setCartLoadingStatus', true)
            this.dispatch('setAddCartItemMessage', "")
            this.dispatch('setDelCartItemMessage', "")
            const promise =  new Promise((resolve) => {
                if (action === 'add') {
                    commit('addCartItem', {
                        good: params.good,
                        quantity: params.quantity
                    })
                } else if (action === 'del') {
                    commit('delCartItem', {
                        good: params.good,
                        quantity: params.quantity
                    })
                } else if (action === 'set') {
                    commit('setCartItem', {
                        good: params.good,
                        quantity: params.quantity
                    })
                }
                resolve()
            })
            promise.then(() => {
                this.dispatch('setCartLoadingStatus', false)
                if (action === 'add') {
                    this.dispatch('setAddCartItemMessage', "Товар добавлен в корзину")
                } else if (action === 'del') {
                    this.dispatch('setDelCartItemMessage', "Товар удален из корзины")
                }
            })
        }
    }
}