<template>
    <div class="breadcrumbs_area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb_content">
                        <ul>
                            <li><router-link :to="{ name: 'catalog' }">Домой</router-link></li>
                            <!-- <li><router-link :to="{ name: 'catalog', query: {page: 1} }">Каталог</router-link></li> -->
                            <li>{{ goodsName }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "GoodBreadCrumbs",
    computed: {
        goodsName () {
            return this.$store.getters.goodsName
        }
    }
}
</script>
