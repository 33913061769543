<template>
    <td class="product_thumb">
        <router-link 
            :to="{ name: 'good', params: { good_id: wishlistItem.good.id}}"
        >
            <img v-if="mainImage" :src="mainImage" :alt="wishlistItem.good.name" width="80">
            <img v-else :src="PreviewImage" alt="" width="80">
        </router-link>
    </td>
    <td class="product_name">
        <router-link 
            :to="{ name: 'good', params: { good_id: wishlistItem.good.id}}"
        >
        {{ wishlistItem.good.name }}
        </router-link>
    </td>
    <td class="product-price">{{ price }} &#8381;</td>
    <td class="product_quantity">{{ balance }} шт.</td>
    <td class="product_total">
        <n-space vertical>
            <n-spin :show="cartAddLoading">
                <a @click="addToCart">В корзину</a>
            </n-spin>
        </n-space> 
    </td>
    <td class="product_remove"><a @click="delWishlistItem" href="#">X</a></td>
</template>

<script>
import {backendPath} from "@/main.js"
import PreviewImage from '@/assets/images/product/ProductPreview.jpg'
export default {
    name: "WishlistItemComponent",
    props: ["WLItem"],
    data () {
        return {
            PreviewImage,
            cartAddLoading: false,
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        wishlistItem () {
            if (this.userToken) {
                return this.WLItem
            } else {
                return {
                    good: this.WLItem
                }
            }
        },
        balance () {
            return Number(this.wishlistItem.good.balance).toFixed(0)
        },
        mainImage () {
            let path = ''
            if (this.wishlistItem.good.images.length > 0) {
                path = backendPath + this.wishlistItem.good.images[0].image.url
            }
            return path
        },
        client () {
            return this.$store.getters.client
        },
        price () {
            if (this.userToken && this.client.is_reseller) {
                return this.wishlistItem.good.price2
            } 
            return this.wishlistItem.good.price1
        },
    },
    methods: {
        addToCart (el) {
            el.preventDefault()
            if (this.userToken) {
                this.cartAddLoading = true
                this.$store.dispatch('addSetDelCartItem', 
                {
                    good_id: this.wishlistItem.good.id,
                    quantity: 1,
                    userToken: this.userToken,
                    action: 'add'
                }).then(() => {
                    setTimeout(() => {
                        this.cartAddLoading = false
                    }, 1000)
                })
            } else {
                this.cartAddLoading = true
                this.$store.dispatch('localCart/addSetDelCartItem',
                { 
                    good: this.wishlistItem.good, 
                    quantity: 1, 
                    action: 'add' 
                }).then(() => {
                    setTimeout(() => {
                        this.cartAddLoading = false
                    }, 1000)
                })
            }
        },
        delWishlistItem (el) {
            el.preventDefault()
            if (this.userToken) {
                this.$store.dispatch('addDelWishlistItem',
                {
                    good_id: this.wishlistItem.good.id,
                    userToken: this.userToken,
                    action: 'del'
                })
            } else {
                this.$store.dispatch('localWishlist/addDelWishlistItem',
                {
                    good: this.wishlistItem.good,
                    action: 'del'
                })
            }
        }
    }
}
</script>

<style>
.product_quantity {
    text-transform: none !important;
}
</style>