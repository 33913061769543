<template>
    <div class="contact_area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="contact_message form">
                        <div>
                            <p>
                                <label> Имя </label>
                                <input name="name" placeholder="Имя *" type="text">
                            </p>
                            <p>
                                <label> Email </label>
                                <input name="email" placeholder="Email *" type="email">
                            </p>
                            <p>
                                <label> Телефон </label>
                                <input name="subject" placeholder="Телефон *" type="text">
                            </p>
                            <div class="contact_textarea">
                                <label> Сообщениe</label>
                                <textarea placeholder="Сообщение *" name="message" class="form-control2"></textarea>
                            </div>
                            <button type="submit"> Отправить</button>
                            <p class="form-messege"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactFormComponent"
}
</script>