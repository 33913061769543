<template>
    <div class="customer_login mt-32">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="account_form">
                        <h2>Авторизация</h2>
                        <div>
                            <p>
                                <label>Email <span>*</span></label>
                                <input 
                                    v-model="inputEmail" 
                                    :class="activeClass" 
                                    type="text"
                                    :disabled="sendPinCodeResultStatus.status == 'success'"
                                >
                            </p>
                            <p>
                                <VuePincodeInput v-show="sendPinCodeResultStatus.status == 'success'" :digits="6" v-model="inputPinCode" />
                            </p>
                            <div v-if="sendPinCodeResultStatus.status == 'success'" class="login_submit">
                                <button @click="loginRequest">
                                    <n-space vertical>
                                        <n-spin :show="loginLoading">
                                            Войти
                                        </n-spin>
                                    </n-space>
                                </button>
                            </div>
                            <div v-else class="login_submit">
                                <button @click="requestForPinCode">
                                    <n-space vertical>
                                        <n-spin :show="getPinLoading">
                                            Получить PIN
                                        </n-spin>
                                    </n-space>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VuePincodeInput from 'vue3-pincode-input'
export default {
    name: "LoginComponent",
    components: {
        VuePincodeInput
    },
    data () {
        return {
            inputEmail: "",
            inputEmailError: false,
            inputPinCode: "",
            message: "",
            loginLoading: false,
            getPinLoading: false
        }
    },
    computed: {
        isValidEmail() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.inputEmail)
        },
        activeClass () {
            let activeClass = ''
            if (this.isValidEmail) {
                activeClass = 'success'
            } else {
                activeClass = 'error'
            }
            if (this.inputEmail=="") {
                activeClass = ''
            }
            return activeClass
        },
        sendPinCodeResultStatus () {
            return this.$store.getters.sendPinCodeResultStatus
        },

    },
    methods: {
        requestForPinCode () {
            if (this.isValidEmail) {
                this.getPinLoading = true
                this.$store.dispatch('requestForPinCode', 
                {
                    inputEmail: this.inputEmail
                }).then(() => {
                    setTimeout(() => {
                        this.getPinLoading = false
                    }, 1500)
                })
            }
        },
        loginRequest () {
            if (this.inputPinCode.length == 6) {
                this.loginLoading = true
                this.$store.dispatch('loginRequest', 
                {
                    inputEmail: this.inputEmail,
                    inputPinCode: this.inputPinCode
                }).then(() => {
                    setTimeout(() => {
                        this.loginLoading = false
                        this.$router.push({name: 'catalog'})
                    }, 1500)
                })
            }
        }
    }
}
</script>

<style>
div.vue-pincode-input-wrapper {
  justify-content: space-around;
}
input.error {
    border-color: #dc3545;
}
input.success {
    border-color: #198754;
}
@media only screen and (max-width: 512px) {
    .vue-pincode-input-wrapper .vue-pincode-input.default {
        width: 50px;
        height: 50px;
    }
}
@media only screen and (max-width: 364px) {
    .vue-pincode-input-wrapper .vue-pincode-input.default {
        width: 45px;
        height: 45px;
    }
}
</style>