<template>
    <BreadcrumbComponent name="Застройщикам ИЖС" />
    <ContactFormComponent />
</template>


<script>
import BreadcrumbComponent from "@/components/Basic/BreadcrumbComponent.vue"
import ContactFormComponent from "@/components/Widgets/ContactFormComponent.vue"
export default {
    name: "ForDevelopersView",
    components: {
        BreadcrumbComponent,
        ContactFormComponent
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Застройщикам ИЖС'
            }
        }
    }
}
</script>