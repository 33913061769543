<template>
    <BreadcrumbComponent name="Контакты" />
    <div class="contact_area">
        <div class="container">
            <div class="row mb-5">
                <div class="col-lg-6 col-md-12">
                    <div class="contact_message content" style="border: none;">
                        <h3>Россия</h3>
                        <ul>
                            <li><i class="fa fa-fax"></i> Адрес : г. Воронеж  ул. Остужева д. 2/1.
                            </li>
                            <li><i class="fa fa-envelope-o"></i> <a href="mailto:89205581808@mail.ru"> 89205581808@mail.ru </a></li>
                            <li>
                                <div class="support_social">
                                    <div>  
                                        <i class="fa fa-phone"></i><a href="tel:+79205581808">т. +7 920 558 18 08</a>
                                    </div>
                                    <div>  
                                        <a target="_blank" href="https://t.me/+79205581808"><i class="fa fa-telegram"></i></a>
                                    </div>
                                    <div>
                                        <a target="_blank" href="https://wa.me/message/U5F4TX45SZKZP1"><i class="fa fa-whatsapp"></i></a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Aeafabc0a29132509cdb7ca37ac9c5d414b8e1c89176bc20eb0610e354f3366d7&amp;source=constructor" width="100%" height="252" frameborder="0"></iframe>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-6 col-md-12">
                    <div class="contact_message content" style="border: none;">
                        <h3>Россия</h3>
                        <ul>
                            <li><i class="fa fa-fax"></i> Адрес : г. Белгород п. Дубовое  ул. Шоссейная д.2.
                            </li>
                            <li><i class="fa fa-envelope-o"></i> <a href="mailto:89205581808@mail.ru"> 89205581808@mail.ru </a></li>
                            <li>
                                <div class="support_social">
                                    <div>  
                                        <i class="fa fa-phone"></i><a href="tel:+79205581808">т. +7 920 558 18 08</a>
                                    </div>
                                    <div>  
                                        <a target="_blank" href="https://t.me/+79205581808"><i class="fa fa-telegram"></i></a>
                                    </div>
                                    <div>
                                        <a target="_blank" href="https://wa.me/message/U5F4TX45SZKZP1"><i class="fa fa-whatsapp"></i></a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ade059682c8a0eaef1f14ee6c564e4f7aa6cd2eed20938a35bf160ee69bffae37&amp;source=constructor" width="100%" height="252" frameborder="0"></iframe>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-6 col-md-12">
                    <div class="contact_message content" style="border: none;">
                        <h3>Узбекистан</h3>
                        <ul>
                            <li><i class="fa fa-fax"></i> Адрес : г. Ташкент ул. Ахсикат д.176.
                            </li>
                            <li><i class="fa fa-envelope-o"></i> <a href="mailto:89205581808@mail.ru"> 89205581808@mail.ru </a></li>
                            <li>
                                <div class="support_social">
                                    <div>  
                                        <i class="fa fa-phone"></i><a href="tel:+998931058913">т. + 998 93 105 89 13</a>
                                    </div>
                                    <div>  
                                        <a target="_blank" href="https://t.me/magazin_poliva1_uz"><i class="fa fa-telegram"></i></a>
                                    </div>
                                    
                                </div>
                            </li>
                        </ul>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Add006df4aed097ab1ded2c87ebd0acd8fa928ff12bb69449fd98190fabd1127b&amp;source=constructor" width="100%" height="252" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadcrumbComponent from "@/components/Basic/BreadcrumbComponent.vue"
export default {
    name: "ContactView",
    components: {
        BreadcrumbComponent
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Контакты'
            }
        }
    }
}
</script>