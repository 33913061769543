export default {
    namespaced: true,
    state: {
        localWishlist: [],
    },
    getters: {
        localWishlist: state => {
            return state.localWishlist
        },
        localWishlistQty: state => {
            return state.localWishlist.length
        },
        localWishlistItemById: state => id => {
            return state.localWishlist.find(wishlistItem => wishlistItem.id === id)
        }
    },
    mutations: {
        loadLocalWishlist (state, payload) {
            state.wishlist = payload
        },
        addDelWishlistItem (state, payload) {
            let wishlistItem = state.localWishlist.find(wishlistItem => wishlistItem.id === payload.id)
            if (wishlistItem) {
                state.localWishlist = state.localWishlist.filter(wishlistItem => wishlistItem.id !== payload.id)
            } else {
                state.localWishlist.push(payload)
            }
        }
    },
    actions: {
        async addDelWishlistItem({commit}, params) {
            let good = params.good
            let action = params.action
            this.dispatch('setWishlistLoadingStatus', true)
            this.dispatch('loadAddWishlistItemMessage', "")
            this.dispatch('loadDelWishlistItemMessage', "")
            const promise =  new Promise((resolve) => {
                commit('addDelWishlistItem', good)
                resolve()
            })
            promise.then(() => {
                this.dispatch('setWishlistLoadingStatus', false)
                if (action === 'add') {
                    this.dispatch('loadAddWishlistItemMessage', "Товар добавлен в избранное")
                } else if (action === 'del') {
                    this.dispatch('loadDelWishlistItemMessage', "Товар удален из избранного")
                }
            })
        },
        clearLocalWishlist({commit}) {
            commit('loadLocalWishlist', [])
        }
    }
}