<template>
    <BreadcrumbComponent name="Личный кабинет" />
    <section class="main_content_area">
        <div class="container">
            <div class="account_dashboard">
                <div class="row">
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <!-- Nav tabs -->
                        <div class="dashboard_tab_button">
                            <ul role="tablist" class="nav flex-column dashboard-list">
                                <li> <a href="#orders" data-bs-toggle="tab" class="nav-link active" aria-selected="true" tabindex="-1" role="tab">Заказы</a></li>
                                <li><a href="#account-details" data-bs-toggle="tab" class="nav-link" aria-selected="false" tabindex="-1" role="tab">Информация</a>
                                </li>
                                <li><a @click="showlogoutModal" href="#" class="nav-link" aria-selected="false" tabindex="-1" role="tab">Выйти</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-9 col-lg-9">
                        <!-- Tab panes -->
                        <div class="tab-content dashboard_content">
                            <OrderListComponent />
                            <div class="tab-pane fade" id="account-details" role="tabpanel">
                                <h3>Информация </h3>
                                <div class="login">
                                    <div class="login_form_container">
                                        <div class="account_login_form">
                                            <form action="#">
                                                <p>Already have an account? <a href="#">Log in instead!</a></p>
                                                <div class="input-radio">
                                                    <span class="custom-radio"><input type="radio" value="1" name="id_gender"> Mr.</span>
                                                    <span class="custom-radio"><input type="radio" value="1" name="id_gender"> Mrs.</span>
                                                </div> <br>
                                                <label>First Name</label>
                                                <input type="text" name="first-name">
                                                <label>Last Name</label>
                                                <input type="text" name="last-name">
                                                <label>Email</label>
                                                <input type="text" name="email-name">
                                                <label>Password</label>
                                                <input type="password" name="user-password">
                                                <label>Birthdate</label>
                                                <input type="text" placeholder="MM/DD/YYYY" value="" name="birthday">
                                                <span class="example">
                                                    (E.g.: 05/31/1970)
                                                </span>
                                                <br>
                                                <span class="custom_checkbox">
                                                    <input type="checkbox" value="1" name="optin">
                                                    <label>Receive offers from our partners</label>
                                                </span>
                                                <br>
                                                <span class="custom_checkbox">
                                                    <input type="checkbox" value="1" name="newsletter">
                                                    <label>Sign up for our newsletter<br><em>You may unsubscribe at any
                                                            moment. For that purpose, please find our contact info in
                                                            the legal notice.</em></label>
                                                </span>
                                                <div class="save_button primary_btn default_button">
                                                    <button type="submit">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <n-modal v-model:show="logoutModal">
        <n-card
        style="width: 600px"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
        >
        <h3 class="post_title">Вы действительно хотите выйти?</h3>
        <template #footer>
            <div class="logout_confirm">
                <button @click="userLogout">Выйти</button>
            </div>
        </template>
        </n-card>
    </n-modal>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { cookiesToken } from "@/main.js"
import BreadcrumbComponent from "@/components/Basic/BreadcrumbComponent.vue"
import OrderListComponent from "@/components/User/Account/OrderListComponent.vue"

export default {
    name: "LoginView",
    setup() {
		const { cookies } = useCookies()
		return { cookies }
	},
    data () {
        return {
            logoutModal: false,
        }
    },
    mounted () {
        let authToken = this.cookies.get(`${cookiesToken}`)
        if (!authToken) {
            this.$router.push({ name: 'login'})
        }
    },
    components: {
        BreadcrumbComponent,
        OrderListComponent
    },
    methods: {
        showlogoutModal () {
            this.logoutModal = true
        },
        userLogout () {
            this.$store.dispatch('setUserToken', '')
            this.$store.dispatch('clearUserInfo', {})
            this.$store.dispatch('clearCart')
            this.$store.dispatch('clearWishlist')
            this.$router.push({ name: 'login' })
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Личный кабинет'
            }
        }
    }
}
</script>

<style scoped>
.n-card__content {
    text-align: center;
}
.logout_confirm {
    text-align: center;
}
.logout_confirm button {
    border: 0;
	cursor: pointer;
	font-size: 16px;
	line-height: 30px;
	padding: 5px 10px;
	text-transform: capitalize;
	color: #ffffff;
	background: #184ca0;
	font-weight: 500;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	margin-bottom: 0;
	border-radius: 5px;
    width: 150px;
}

.logout_confirm button:hover {
  background: #333;
}
</style>