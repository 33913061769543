<template>
    <BreadcrumbComponent name="Онлайн обучение" />
    <ContactFormComponent />
</template>


<script>
import BreadcrumbComponent from "@/components/Basic/BreadcrumbComponent.vue"
import ContactFormComponent from "@/components/Widgets/ContactFormComponent.vue";
export default {
    name: "OnlineTrainingView",
    components: {
        BreadcrumbComponent,
        ContactFormComponent
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Онлайн обучение'
            }
        }
    }
}
</script>